@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cobane';
  src: url('../public/assets/fonts/Cobane/Brink\ -\ BR\ Cobane\ Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Cobane';
  src: url('../public/assets/fonts/Cobane/Brink\ -\ BR\ Cobane\ Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Cobane';
  src: url('../public/assets/fonts/Cobane/Brink\ -\ BR\ Cobane\ SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Cobane';
  src: url('../public/assets/fonts/Cobane/Brink\ -\ BR\ Cobane\ Bold.otf');
  font-weight: 700;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:focus,
textarea:focus {
  outline: none;
}

.banner-info-card {
  width: var(--page-width, 100%);
}

/* TODO: ask if default color is ok ? or another solution when theme is not already fetch */
:root {
  --theme-primary-100: #e1f0f8;
}

select {
  background: url('assets/svg/icon-dropdown.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
}

.react-tabs__tab {
  @apply w-1/2 text-center md:w-auto md:text-left px-12 py-6 inline-block cursor-pointer font-bold text-sm;
}

.Mui-selected.react-tabs__tab {
  @apply text-primary-200 border-b-4 border-primary-200 appearance-none outline-none;
}

@layer components {
  main {
    min-height: calc(100vh - 87px - theme(spacing.header-sm));
  }

  main.main--with-big-footer {
    min-height: calc(100vh - 87px - 219px - theme(spacing.header-sm));
  }

  @media screen(md) {
    main {
      min-height: calc(100vh - 87px - theme(spacing.header-md));
    }

    main.main--with-big-footer {
      min-height: calc(100vh - 87px - 219px - theme(spacing.header-md));
    }
  }
}
