@import '../../assets/scss/mixins';

.Button {
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  font-weight: bold;

  @include for-size(desktop-up) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.ButtonPrimary {
  color: white;
  @include themed(background-color, primary-200);

  &:hover {
    @include themed(background-color, primary-300);
  }

  &:active {
    @include themed(background-color, primary-400);
  }
}

.ButtonSecondary {
  color: white;
  @include themed(background-color, secondary-200);

  &:hover {
    @include themed(background-color, secondary-300);
  }

  &:active {
    @include themed(background-color, secondary-400);
  }
}

.ButtonOutlinedPrimary {
  @include themed(border, primary-200, 1px solid);
  @include themed(color, primary-200);
  background-color: white;

  &:hover {
    @include themed(background-color, primary-100);
  }

  &:active {
    @include themed(background-color, primary-200);
    color: white;
  }
}