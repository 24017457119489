@import '../../assets/scss/mixins';

.Wrapper {
  @apply p-4 border-2 rounded-lg flex gap-4 items-start;
}

.Content {
}

.Title {
  @apply font-bold;
}

.Wrapper {
  &.Info {
    @include themed(background-color, primary-300);
    @include themed(border, primary-400, 2px solid);
    color: white;
  }
}

.Wrapper.Error {
  @apply bg-tags-error-200 border-tags-error-400 text-tags-error-400;
}

.Wrapper.Success {
  @apply bg-tags-success-200 border-tags-success-400 text-tags-success-400;
}
